import { GameListItem } from '@flocards/common/src/GameListItem';
import { Game } from '@flocards/common/src/Game';
import { api } from './Api';

export class GameService {
  public async getGame(categoryId: string, collectionId: string, gameId: string) {
    const result = await api.getGameJSONById(categoryId, collectionId, gameId);
    return result.data as Game;
  }

  public async getGames(collectionId: string, categoryId: string) {
    try {
      const result = await api.getGameList(collectionId, categoryId);
      const parsedGames: GameListItem[] = result.data.games.map((game: any) => {
        return {
          id: game.id,
          title: game.title,
          previewImage: game.previewImage,
        };
      });
      return parsedGames;
    } catch {
      throw new Error('unable to parse received games');
    }
  }
}

export const gameService = new GameService();
