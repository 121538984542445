import { Game } from "@flocards/common/src/Game";
import { Phase } from "@flocards/common/src/Phase";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { gameService } from "../services/GameService";
import { GameCompletedComponent } from "./GameCompletedComponent";
import { PhaseComponent } from "./PhaseComponent";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { gameSelectionRoute } from "../gameSelection/GameSelectionComponent";
import { useCallback } from "react";

export const gameRoute = "/:categoryId/:collectionId/:gameId/game";

export function GameComponent() {
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
  const [currentPhase, setCurrentPhase] = useState<Phase | undefined>(undefined);
  const [game, setGame] = useState<Game | undefined>(undefined);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const { categoryId, collectionId, gameId } = useParams<{
    categoryId: string;
    collectionId: string;
    gameId: string;
  }>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const game = await gameService.getGame(categoryId, collectionId, gameId);
      game.phases.map((p) => {
        p.tasks = p.tasks.sort(() => Math.random() - 0.5);
        return p;
      });
      setGame(game);
      setCurrentPhase(game.phases[currentPhaseIndex]);
    })();
  }, [categoryId, collectionId, gameId, currentPhaseIndex]);

  const moveToNextPhase = useCallback(
    (failedAttempts: string[]) => {
      if (currentPhase) {
        const calculatedScore = score + currentPhase?.tasks.filter((t) => t.solutionId).length - failedAttempts.length;
        setScore(calculatedScore);
      }
      if (game?.phases && currentPhaseIndex < game.phases.length - 1) {
        setCurrentPhaseIndex((curr) => curr + 1);
      } else {
        setIsCompleted(true);
      }
    },
    [currentPhaseIndex, game, currentPhase, score]
  );

  function onGameExit() {
    history.push(generatePath(gameSelectionRoute, { categoryId, collectionId }));
  }

  return (
    <Box position="fixed" height="100%" width="100%">
      {currentPhase && !isCompleted && game && (
        <PhaseComponent currentPhase={currentPhase} onPhaseCompleted={moveToNextPhase} />
      )}
      {isCompleted && game && (
        <GameCompletedComponent score={score} gameId={game.gameId} onGameExit={onGameExit}></GameCompletedComponent>
      )}
    </Box>
  );
}
