import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { storageService } from './services/StorageService';

export const ProtectedRoute: FC<RouteProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Route {...rest}>
      <Authorize>{children}</Authorize>
    </Route>
  );
};

const Authorize: FC = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const loggedInUser = storageService.getLoggedInUser();
      if (!loggedInUser) {
        history.push('/');
      }
    })();
  }, [history]);

  return <Box>{children}</Box>;
};
