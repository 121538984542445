import { User } from '@flocards/common/src/User';
import { Box, Button, Typography } from '@mui/material';
import { UserBubble } from './UserSelection';
import { Colors } from '../theme';

export function IdentifierCodeDisplay(props: {
  user: User;
  enterGame: (user: User) => void;
}) {
  function saveIdentifier() {
    const element = document.createElement('a');
    const text = `Spieler: ${props.user.nickname}, Anmeldecode: ${props.user.userId}`;
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', 'flocards-anmeldecode');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography align="center" gutterBottom color="primary">
          Dies ist dein generierter Anmeldecode:
        </Typography>
        <Box padding={1} margin={1} border={`2px solid ${Colors.Blue}`}>
          <Typography gutterBottom color="primary" variant="subtitle2">
            {props.user.userId}
          </Typography>
        </Box>
        <Typography align="center" gutterBottom color="primary">
          Wichtig: Notiere dir diesen Code für spätere Anmeldungen oder lade ihn herunter!
        </Typography>
        <Box padding={2}>
          <Button color="primary" variant="contained" onClick={saveIdentifier}>
            Herunterladen
          </Button>
        </Box>
        <UserBubble backgroundColor={Colors.BubbleYellow} onClick={() => props.enterGame(props.user)}>
          <Typography variant="subtitle2" color="primary">
            Spiel starten
          </Typography>
        </UserBubble>
      </Box>
    </Box>
  );
}
