import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GameListItem } from '@flocards/common/src/GameListItem';
import { gameService } from '../services/GameService';
import { Colors } from '../theme';
import { imageService } from '../services/ImageService';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { gameRoute } from '../game/GameComponent';
import { CurrentUser } from '../MenuContext';
import { userService } from '../services/UserService';
import { NoCoinsAlert } from '../coins/NoCoinsAlert';

export const gameSelectionRoute = '/:categoryId/:collectionId/game-selection';
interface RouteParams {
  collectionId: string;
  categoryId: string;
}

export function GameSelectionComponent() {
  const [games, setGames] = useState<GameListItem[]>([]);
  const history = useHistory();
  const { collectionId, categoryId } = useParams<RouteParams>();
  const { user, setUser } = useContext(CurrentUser);
  const coinsPerGame = 1;
  const [isNoCoinsDialogOpen, setIsCoinsDialogOpen] = useState(false);

  async function onGameSelect(gameId: string) {
    if (user && user.coins >= coinsPerGame) {
      const updatedUser = await userService.updateCoins(-coinsPerGame, user.userId);
      setUser(updatedUser);
      history.push(generatePath(gameRoute, { categoryId, collectionId, gameId }));
    } else {
      setIsCoinsDialogOpen(true);
    }
  }

  useEffect(() => {
    (async () => {
      const gameList = await gameService.getGames(collectionId, categoryId);
      setGames(gameList);
    })();
  }, [categoryId, collectionId]);

  return (
    <>
      <Box>
        <Grid container justifyContent="center">
          <Grid item lg={4} md={4} sm={8} xs={12}>
            {games.map((game) => (
              <ListItem
                key={game.id}
                onClick={() => {
                  onGameSelect(game.id);
                }}
              >
                <GameThumbnail url={game.previewImage}></GameThumbnail>
                <Box>
                  <Typography color="primary">{game.title}</Typography>
                </Box>
              </ListItem>
            ))}
          </Grid>
        </Grid>
      </Box>
      {isNoCoinsDialogOpen && <NoCoinsAlert onClose={() => setIsCoinsDialogOpen(false)} />}
    </>
  );
}

const ListItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${Colors.Blue};
  margin: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const GameThumbnail = styled.div`
  width: 30%;
  margin: 8px;
  background-image: ${(props: { url: string }) => `url('${imageService.getImageUrl(props.url)}')`};
  background-size: cover;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;
