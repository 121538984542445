import { RegistrationComponent } from "./registration/RegistrationComponent";
import { CategorySelection, categorySelectionRoute } from "./gameSelection/CategorySelection";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CollectionSelection, collectionSelectionRoute } from "./gameSelection/CollectionSelection";
import { GameSelectionComponent, gameSelectionRoute } from "./gameSelection/GameSelectionComponent";
import { GameComponent, gameRoute } from "./game/GameComponent";
import { ProtectedRoute } from "./ProtectedRoute";
import { MenuComponent } from "./MenuComponent";
import { UserContext } from "./MenuContext";
import { BuyCoins, buyCoinsRoute } from "./coins/BuyCoins";
import { CoinsPurchaseSuccess, coinsPurchaseSuccessRoute } from "./coins/CoinPurchaseSuccess";
import { CoinsPurchaseFailure, coinsPurchaseFailureRoute } from "./coins/CoinPurchaseFailure";
import { ProcessPaymentPage, processPaymentRoute } from "./coins/ProcessPayment";

function App() {
  return (
    <>
      <UserContext>
        <BrowserRouter>
          <MenuComponent />
          <Switch>
            <Route exact path="/">
              <RegistrationComponent></RegistrationComponent>
            </Route>
            <ProtectedRoute path={categorySelectionRoute}>
              <CategorySelection></CategorySelection>
            </ProtectedRoute>
            <ProtectedRoute path={collectionSelectionRoute}>
              <CollectionSelection></CollectionSelection>
            </ProtectedRoute>
            <ProtectedRoute path={gameSelectionRoute}>
              <GameSelectionComponent></GameSelectionComponent>
            </ProtectedRoute>
            <ProtectedRoute path={gameRoute}>
              <GameComponent></GameComponent>
            </ProtectedRoute>
            <ProtectedRoute path={buyCoinsRoute}>
              <BuyCoins></BuyCoins>
            </ProtectedRoute>
            <ProtectedRoute path={coinsPurchaseSuccessRoute}>
              <CoinsPurchaseSuccess></CoinsPurchaseSuccess>
            </ProtectedRoute>
            <ProtectedRoute path={coinsPurchaseFailureRoute}>
              <CoinsPurchaseFailure></CoinsPurchaseFailure>
            </ProtectedRoute>
            <ProtectedRoute path={processPaymentRoute}>
              <ProcessPaymentPage />
            </ProtectedRoute>
          </Switch>
        </BrowserRouter>
      </UserContext>
    </>
  );
}

export default App;
