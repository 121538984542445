import { storageService } from "./StorageService";
import { CoinsSet } from "./../../../common/src/CoinsSet";
import axios, { AxiosResponse } from "axios";
import { User } from "@flocards/common/src/User";
import { Game } from "@flocards/common/src/Game";
import { Category } from "@flocards/common/src/Category";
import { Collection } from "@flocards/common/src/Collection";
import { GameListItem } from "@flocards/common/src/GameListItem";
import { GameStateUpdate } from "@flocards/common/src/GameStateUpdate";

export const bucketUrl = "https://s3.eu-central-1.amazonaws.com/com.xitaso.flocards.data.production";
class Api {
  // private backendUrl = `${window.location.protocol}//${window.location.hostname}${
  //   window.location.port && `:${window.location.port}`
  // }/api`;

  private backendUrl = `${window.location.origin}/api`;

  public async getUser(identifier: string): Promise<AxiosResponse<User>> {
    return await axios.get<User>(`${this.backendUrl}/users/${identifier}`);
  }

  public async registerUser(nickname: string): Promise<AxiosResponse<User>> {
    return await axios.post<User>(`${this.backendUrl}/users`, {
      nickname: nickname,
    });
  }

  public async updateGameState(update: GameStateUpdate, userId: string): Promise<AxiosResponse> {
    return await axios.patch(`${this.backendUrl}/users/gameState/${userId}`, { gameStateUpdate: update });
  }

  public async updateCoins(amount: number, userId: string): Promise<AxiosResponse> {
    return await axios.patch(`${this.backendUrl}/users/coins/${userId}`, { coins: amount });
  }

  /* Get new game JSON with new data structure */
  public async getGameJSONById(categoryId: string, collectionId: string, gameId: string): Promise<AxiosResponse<Game>> {
    return await axios.get(`${bucketUrl}/${categoryId}/${collectionId}/${gameId}/game.json`);
  }

  public async getCategories(): Promise<AxiosResponse<{ categories: Category[] }>> {
    return await axios.get(`${bucketUrl}/categories.json`);
  }

  public async getCollections(categoryId: string): Promise<AxiosResponse<{ collections: Collection[] }>> {
    return await axios.get(`${bucketUrl}/${categoryId}/collections.json`);
  }

  public async getGameList(
    collectionId: string,
    categoryId: string
  ): Promise<AxiosResponse<{ games: GameListItem[] }>> {
    return await axios.get(`${bucketUrl}/${categoryId}/${collectionId}/gameList.json`);
  }

  public async createOrder(
    userId: string,
    coinsSet: CoinsSet
  ): Promise<{ orderId: string; approvalLink: { href: string } }> {
    return (await axios.post(`${this.backendUrl}/payment/${userId}`, { set: coinsSet })).data;
  }

  public async tryCapturePayment(): Promise<User> {
    const orderId = storageService.getOpenTransactionid();
    return (await axios.post(`${this.backendUrl}/payment/try-capture-payment/${orderId}`)).data;
  }
}

export const api = new Api();
