import { Collection } from '@flocards/common/src/Collection';
import { api } from './Api';

class CollectionService {
  public async getCollections(categoryId: string): Promise<Collection[]> {
    try {
      const result = await api.getCollections(categoryId);
      const parsedCategories: Collection[] = result.data.collections.map((collection: any) => {
        return {
          id: collection.id,
          title: collection.title,
          subtitle: collection.subtitle,
          imageUrl: collection.imageUrl,
        };
      });
      return parsedCategories;
    } catch {
      throw new Error('unable to parse received collections');
    }
  }
}

export const collectionService = new CollectionService();
