import { Box, Button, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { userService } from "../services/UserService";
import { ReactComponent as PartyIcon } from "../assets/party.svg";
import { CurrentUser } from "../MenuContext";

export function GameCompletedComponent(props: { score: number; gameId: string; onGameExit: () => void }) {
  const { score, gameId, onGameExit } = props;
  const { user, setUser } = useContext(CurrentUser);

  useEffect(() => {
    (async () => {
      if (user) {
        const updatedUser = await userService.updateGameState({ score: score, playedSet: gameId }, user.userId);
        setUser(updatedUser);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser, gameId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="80vh"
      width="100%"
      alignItems="center"
      padding="16px">
      <Typography variant="h4" color="primary">
        Prima! Dein Punktestand wurde um {props.score} erhöht!
      </Typography>
      <BounceInAnimation>
        <PartyIcon />
      </BounceInAnimation>
      <div>
        <div>
          <Button color="primary" variant="contained" onClick={onGameExit}>
            Weiter
          </Button>
        </div>
      </div>
    </Box>
  );
}
const BounceInAnimation = styled(Box)`
  width: 35vh;
  max-width: 600px;
  animation: 1s ${keyframes`
    {
        0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
    }`};
  animation-fill-mode: forwards;
`;
