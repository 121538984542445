import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import ReplyIcon from "@mui/icons-material/Reply";
import FeedbackIcon from "@mui/icons-material/Feedback";
import GradeIcon from "@mui/icons-material/Grade";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ArticleIcon from "@mui/icons-material/Article";
import MenuIcon from "@mui/icons-material/Menu";
import { generatePath, useHistory } from "react-router-dom";
import { categorySelectionRoute } from "./gameSelection/CategorySelection";
import { useContext, useEffect, useState } from "react";
import { storageService } from "./services/StorageService";
import { CurrentUser } from "./MenuContext";
import { Colors } from "./theme";
import { ReactComponent as Logo } from "./assets/flocards_logo.svg";
import { ReactComponent as CoinIcon } from "./assets/Flotaler.svg";
import { buyCoinsRoute } from "./coins/BuyCoins";
import { userService } from "./services/UserService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const displayBackButtonForRoutes = ["collection-selection", "game-selection", "game"];

export function MenuComponent() {
  const history = useHistory();
  const [showDrawer, setShowDrawer] = useState(false);
  const { user, setUser } = useContext(CurrentUser);
  const [backButtonVisible, setBackButtonVisible] = useState(false);

  useEffect(() => {
    history.listen(() => {
      const matchingRoute = displayBackButtonForRoutes.find((route) => history.location.pathname.includes(route));
      if (matchingRoute) {
        setBackButtonVisible(true);
      } else {
        setBackButtonVisible(false);
      }
    });
    (async () => {
      let loggedInUser = storageService.getLoggedInUser();
      if (loggedInUser) {
        loggedInUser = await userService.getUser(loggedInUser?.userId);
      }
      setUser(loggedInUser);
    })();
  }, [setUser, history]);

  function goToRegistration() {
    setShowDrawer(false);
    setUser(undefined);
    history.push("/");
  }

  function goToCategorySelection() {
    setShowDrawer(false);
    history.push(generatePath(categorySelectionRoute));
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row-reverse"
          flexGrow={1}
          justifyContent="space-between"
          width="100%"
          color="white">
          <IconButton color="inherit" onClick={() => setShowDrawer(true)} size="large">
            <MenuIcon fontSize="large"></MenuIcon>
          </IconButton>
          {backButtonVisible && (
            <IconButton
              color="inherit"
              onClick={() => {
                history.goBack();
              }}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={showDrawer} onClose={() => setShowDrawer(false)}>
        <Box minWidth={250} color={Colors.Blue}>
          <Box maxWidth={250} p={3} bgcolor="#eee">
            <Logo />
          </Box>
          <List sx={{ pt: 0 }}>
            {user && (
              <>
                <Box color="inherit" bgcolor="#eee">
                  <ListItem>
                    <ListItemText
                      primary={user.nickname}
                      color="primary"
                      primaryTypographyProps={{ fontWeight: "bold", fontSize: "1.2rem" }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ color: Colors.BubbleGreen }}>
                      <GradeIcon color="inherit" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.state.score}
                      primaryTypographyProps={{ color: Colors.BubbleGreen, fontWeight: "bold" }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ color: user.coins > 0 ? "secondary" : "red" }}>
                      <Box width="35px" height="35px">
                        <CoinIcon color="inherit" />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={user.coins}
                      primaryTypographyProps={{
                        color: user.coins > 0 ? "secondary" : "red",
                        fontWeight: "bold",
                      }}
                    />
                    <Link href={buyCoinsRoute} underline="none">
                      <Button size="small" variant={user.coins > 0 ? "text" : "contained"}>
                        Aufladen
                      </Button>
                    </Link>
                  </ListItem>
                  <Divider />
                </Box>
                <ListItemButton onClick={goToCategorySelection}>
                  <ListItemIcon sx={{ color: Colors.Blue }}>
                    <StyleIcon color="inherit" />
                  </ListItemIcon>
                  <ListItemText primary="Spielauswahl" />
                </ListItemButton>
                <ListItemButton onClick={goToRegistration}>
                  <ListItemIcon sx={{ color: Colors.Blue }}>
                    <LogoutIcon color="inherit" />
                  </ListItemIcon>
                  <ListItemText primary="Abmelden" />
                </ListItemButton>
                <Divider />
              </>
            )}
            <ListItem>
              <ListItemIcon sx={{ color: Colors.Blue }}>
                <FeedbackIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    underline="none"
                    color="inherit"
                    href="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__h_ETDlURFNDQTNHVFlQSThWS0tSQlo5N0ZaVjgxMy4u"
                    target="_blank">
                    Feedback
                  </Link>
                }></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: Colors.Blue }}>
                <ReplyIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link underline="none" color="inherit" href="https://www.flocards.de" target="_blank">
                    Über FLOCARDS
                  </Link>
                }></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: Colors.Blue }}>
                <InfoIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link underline="none" color="inherit" href="https://www.flocards.de/impressum" target="_blank">
                    Impressum
                  </Link>
                }></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: Colors.Blue }}>
                <PrivacyTipIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link underline="none" color="inherit" href="https://www.flocards.de/datenschutz" target="_blank">
                    Datenschutz
                  </Link>
                }></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ color: Colors.Blue }}>
                <ArticleIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    underline="none"
                    color="inherit"
                    href="https://www.flocards.de/flocardsonline-terms"
                    target="_blank">
                    Nutzungsbedingungen
                  </Link>
                }></ListItemText>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
}
