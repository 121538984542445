import { Box, Button, Link, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { ReactComponent as CoinIcon } from "../assets/Flotaler.svg";
import { categorySelectionRoute } from "../gameSelection/CategorySelection";
import { CurrentUser } from "../MenuContext";

export const coinsPurchaseSuccessRoute = "/coins-purchase-success";

export const CoinsPurchaseSuccess: FC = () => {
  const { user } = useContext(CurrentUser);

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" p={5} alignItems="center">
      <Typography variant="h4" gutterBottom color="primary">
        Hurra! Aufladung erfolgreich
      </Typography>
      <Typography gutterBottom color="primary">
        Deine Aufladung hat geklappt. Dein FLOTALER-Kontostand liegt bei:
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Box width="50px" height="50px" mr={1}>
          <CoinIcon />
        </Box>
        <Typography color="secondary" variant="h5" sx={{ fontWeight: "bold" }}>
          {user?.coins}
        </Typography>
      </Box>
      <Link href={categorySelectionRoute} underline="none">
        <Button variant="contained">Weiterspielen</Button>
      </Link>
    </Box>
  );
};
