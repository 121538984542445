import { User } from "@flocards/common/src/User";
import React, { FC, useCallback, useState } from "react";
import { storageService } from "./services/StorageService";

export const CurrentUser = React.createContext<{user?: User; setUser: (user?: User) => void}>({user: undefined, setUser: () => {}});
export const UserContext :FC = ({children}) => {
    const [user, setUser] = useState<User | undefined>()

    const customSetUser = useCallback((user?: User) => {
        if(!!user) {
            storageService.logInUser(user);
        } else {
            storageService.logOutUser();
        }
        setUser(user);
    }, []);

    return (
        <CurrentUser.Provider value={{user, setUser: customSetUser}}>
            {children}
        </CurrentUser.Provider>
    )
}