import { Box, CircularProgress } from "@mui/material";
import { FC, useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CurrentUser } from "../MenuContext";
import { api } from "../services/Api";
import { coinsPurchaseFailureRoute } from "./CoinPurchaseFailure";
import { coinsPurchaseSuccessRoute } from "./CoinPurchaseSuccess";

export const processPaymentRoute = "/process-payment";

export const ProcessPaymentPage: FC = () => {
  const history = useHistory();
  const {setUser} = useContext(CurrentUser)

  useEffect(() => {
    (async () => {
      const updatedUser = await api.tryCapturePayment();
      if (updatedUser) {
        setUser(updatedUser);
        history.push(coinsPurchaseSuccessRoute);
      } else {
        history.push(coinsPurchaseFailureRoute);
      }
    })();
  }, [history, setUser]);

  return (
    <Box display="flex" position="fixed" justifyContent="center" alignItems="center" height="80%" width="100%">
      <CircularProgress />
    </Box>
  );
};
