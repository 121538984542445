import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { ReactComponent as CoinIcon } from "../assets/Flotaler.svg";
import { useState } from "react";
import { buyCoinsRoute } from "./BuyCoins";

export function NoCoinsAlert(props: { onClose: () => void }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Oh Nein! Du hast keine FLOTALER mehr</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
          <Box width="50px" height="50px">
            <CoinIcon />
          </Box>
          <Typography color="red" variant="h5">
            0
          </Typography>
        </Box>
        <DialogContentText>
          Ein Spiel kostet einen FLOTALER. Bitte deine Eltern, dein FLOTALER-Konto aufzuladen, um weiter spielen zu
          können.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link href={buyCoinsRoute} underline="none">
          <Button variant="contained">Jetzt Aufladen</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}
