import { createTheme } from "@mui/material/styles";

export const Colors = {
  Blue: "#174078",
  Orange: "#ffab62",
  Red: "#D57D7D",
  BubbleYellow: "#ffec70",
  BubbleOrange: "#ffac63",
  BubblePink: "#f18bbc",
  BubbleGreen: "#73cc73",
  BubbleBlue: "#45cbff",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Blue,
    },
    secondary: {
      main: Colors.BubbleOrange,
    },
  },
  typography: {
    h4: { fontSize: "1.6rem" },
    h6: { fontWeight: "bold" },
    subtitle2: { fontWeight: "bold" },
  },
});
