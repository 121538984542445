import { Box, Fab, Typography } from '@mui/material';
import { AreaLayouter } from './AreaLayouter';
import { Phase } from '@flocards/common/src/Phase';
import { useEffect, useRef, useState } from 'react';
import { SolutionComponent } from './SolutionComponent';
import { TaskComponent } from './TaskComponent';
import { FailedAttemptComponent } from './FailedAttemptComponent';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import React from 'react';
import { Colors } from '../theme';
import { useWindowResize } from '../hooks/useWindowResize';

const readOut = (text: string) => {
  const synth = window.speechSynthesis;
  if (!synth.speaking) {
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
  }
};

const gap = 10;
const margin = 10;
const maxElementsPerRow = 3;

function calculateMaxCardWidth(areaHeight: number, screenWidth: number, setMaxImageWidth: (width: number) => void) {
  let elementHeight = (areaHeight - gap - margin * 2) / 2;

  const maxGridWidth = 3 * elementHeight + 2 * gap + 2 * margin;
  if (maxGridWidth > screenWidth) {
    elementHeight = (screenWidth - margin * 2 - (maxElementsPerRow - 1) * gap) / maxElementsPerRow;
  }
  setMaxImageWidth(elementHeight);
}

export function PhaseComponent(props: { currentPhase: Phase; onPhaseCompleted: (failedAttemps: string[]) => void }) {
  const [solvedSolutionIds, setSolvedSolutionIds] = useState<string[]>([]);
  const [solvedTaskIds, setSolvedTaskIds] = useState<string[]>([]);
  const [currentAttempt, setCurrentAttempt] = useState<
    { taskId: string; taskSolutionId: string; attemptedSolutionId: string } | undefined
  >(undefined);
  const [tasksWithFailedAttempts, setTasksWithFailedAttempts] = useState<string[]>([]);

  const taskRef = useRef<HTMLDivElement>(null);
  const solutionRef = useRef<HTMLDivElement>(null);
  const [maxImageWidth, setMaxImageWidth] = useState<number | undefined>(undefined);
  const [draggedTask, setDraggedTask] = useState<string | undefined>(undefined);
  const windowSize = useWindowResize();

  useEffect(() => {
    if (taskRef.current?.offsetHeight) {
      calculateMaxCardWidth(taskRef.current?.offsetHeight, windowSize.width, setMaxImageWidth);
    }
  }, [windowSize]);

  useEffect(() => {
    if (props.currentPhase.tasks.filter((t) => !!t.solutionId).every((t) => solvedTaskIds.includes(t.id))) {
      setTimeout(() => {
        props.onPhaseCompleted(tasksWithFailedAttempts);
        setTasksWithFailedAttempts([]);
        setSolvedTaskIds([]);
        setSolvedSolutionIds([]);
      }, 1000);
    }
  // We do not want to add props to the deps array here
  // eslint-disable-next-line
  }, [solvedTaskIds]);

  return (
    <Box height={`${windowSize.height - 64}px`}>
      <Box
        pt={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="20%"
        maxWidth="100%"
        paddingLeft={2}
        paddingRight={2}
      >
        <Box color="white" paddingLeft={1}>
        <Fab color="secondary" size="small" onClick={() => {readOut(props.currentPhase.instruction)}}>
          <VolumeUpIcon sx={{color: "white"}}/>
        </Fab>
        </Box>
        <Box my={2} color={Colors.Blue}>
          <Typography variant="subtitle2">{props.currentPhase.instruction}</Typography>
        </Box>
      </Box>
      <AreaLayouter
        ref={taskRef}
        isTaskArea={true}
        gridGap={gap}
        width={maxImageWidth ?? 0}
        tasks={props.currentPhase.tasks}
      >
        {props.currentPhase.tasks?.map((t, i) => (
          <React.Fragment key={t.id}>
            {currentAttempt?.taskId !== t.id ? (
              <TaskComponent
                key={t.id}
                task={t}
                index={i}
                solvedTasks={solvedTaskIds}
                setSolvedTaskIds={setSolvedTaskIds}
                setSolvedSolutionIds={setSolvedSolutionIds}
                isDragging={draggedTask !== undefined}
                setDraggable={setDraggedTask}
                maxImageWidth={maxImageWidth || 0}
                setCurrentAttempt={setCurrentAttempt}
                setTasksWithFailedAttempts={setTasksWithFailedAttempts}
              />
            ) : (
              <Box height="100%"></Box>
            )}
          </React.Fragment>
        ))}
      </AreaLayouter>
      <AreaLayouter
        ref={solutionRef}
        isTaskArea={false}
        gridGap={gap}
        width={maxImageWidth ?? 0}
        tasks={props.currentPhase.solutions}
      >
        {props.currentPhase.solutions.map((s, i) => (
          <Box position="relative" key={s.id}>
            <SolutionComponent
              solution={s}
              solvedSolutions={solvedSolutionIds}
              tasks={props.currentPhase.tasks}
              solvedTaskIds={solvedTaskIds}
              maxImageWidth={maxImageWidth || 0}
            />
            {currentAttempt &&
              currentAttempt.attemptedSolutionId === s.id &&
              currentAttempt.taskSolutionId !== s.id && (
                <FailedAttemptComponent
                  resetCurrentAttepmt={() => setCurrentAttempt(undefined)}
                  task={props.currentPhase.tasks.find((t) => t.id === currentAttempt.taskId)}
                  solution={props.currentPhase.solutions.find((s) => s.id === currentAttempt.attemptedSolutionId)}
                />
              )}
          </Box>
        ))}
      </AreaLayouter>
    </Box>
  );
}
