import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { ReactComponent as CoinIcon } from "../assets/Flotaler.svg";
import { CurrentUser } from "../MenuContext";
import { api } from "../services/Api";
import { storageService } from "../services/StorageService";
import { ReactComponent as PaypalLogo } from "../assets/paypal.svg";

export const buyCoinsRoute = "/buy-coins";

export const purchaseOptions = [
  { coins: 10, price: 0.99, name: "Ausprobierer", color: "#73cc73c7" },
  { coins: 100, price: 4.99, name: "Fan", color: "#45cbff70" },
  { coins: 1000, price: 9.99, name: "Vielspieler", color: "#ffab62e2" },
];

export const BuyCoins: FC = () => {
  const defaultValue = 1;
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(defaultValue);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const { user } = useContext(CurrentUser);
  const [onGoingPurchase, setOngoingPurchase] = useState(false);

  const startPayment = async () => {
    setOngoingPurchase(true);
    if (user) {
      const response = await api.createOrder(user.userId, {
        amount: purchaseOptions[selectedPurchaseOption].coins,
        price: purchaseOptions[selectedPurchaseOption].price,
      });
      storageService.savePaypalOrderId(response.orderId);
      window.open(response.approvalLink.href, "_self");
      setOngoingPurchase(false);
    }
  };
  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" p={5} alignItems="center">
      <Typography variant="h4" gutterBottom color="primary">
        FLOTALER-Konto aufladen
      </Typography>
      <Typography color="primary">
        FLOCARDS.online bietet eine pädagogisch wertvolle Beschäftigung und ein ständig wachsendes Angebot an Aufgaben
        zur Förderung der Entwicklung.
      </Typography>
      <Typography gutterBottom color="primary">
        Um die fortlaufende Weiterentwicklung der Lernapp zu ermöglichen, ist nach den ersten 25 Sets eine Aufladung des
        FLOTALER-Kontos notwendig. Ein Spiel kostet einen FLOTALER.
      </Typography>
      {user && (
        <FormGroup>
          <FormControl fullWidth>
            <RadioGroup sx={{ my: 3 }} defaultValue={defaultValue} name="radio-buttons-group">
              <>
                {purchaseOptions.map((option, index) => (
                  <FormControlLabel
                    key={option.coins}
                    value={index}
                    sx={{ my: 1, "> .MuiTypography-root": { width: "100%" } }}
                    control={
                      <Radio
                        onChange={() => {
                          setSelectedPurchaseOption(index);
                        }}
                        sx={{ "&.Mui-checked": { color: "secondary" } }}
                      />
                    }
                    label={
                      <BuyOption
                        amount={option.coins}
                        priceInEur={option.price}
                        name={option.name}
                        color={option.color}
                      />
                    }
                  />
                ))}
              </>
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ mb: "0.35em" }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={false}
                  onChange={() => setAcceptedTermsAndConditions(!acceptedTermsAndConditions)}></Checkbox>
              }
              label={
                <Typography color="primary" pb={1}>
                  Ich akzeptiere die
                  <Link href="https://www.flocards.de/flocardsonline-terms" target="_blank">
                    {" "}
                    Nutzungsbedingungen
                  </Link>
                </Typography>
              }
            />
          </FormControl>
          <FormControl>
            <Button
              variant="contained"
              onClick={startPayment}
              sx={{
                backgroundColor: "#ffc439",
                py: 1.5,
                "&.Mui-disabled": { backgroundColor: "#ffc02e57" },
                "&:hover": { backgroundColor: "#db9a03c0" },
              }}
              startIcon={<PaypalLogo />}
              disabled={!acceptedTermsAndConditions || onGoingPurchase}></Button>
          </FormControl>
        </FormGroup>
      )}
    </Box>
  );
};

const BuyOption = ({
  amount,
  priceInEur,
  name,
  color,
}: {
  amount: number;
  priceInEur: number;
  name: string;
  color: string;
}) => {
  return (
    <Paper sx={{ bgcolor: color }}>
      <Box display="flex" flexDirection="column" alignItems="center" py={1} px={2}>
        <Box width="60px" height="60px" mr={1} mb={1}>
          <CoinIcon color="inherit" />
        </Box>
        <Typography variant="h5" color="primary">
          {name}
        </Typography>
        <Box py={2}>
          <Typography variant="body1" color="primary">
            {amount} FLOTALER
          </Typography>
        </Box>
        <Typography variant="body1" color="primary" sx={{ fontWeight: "bold" }}>
          {priceInEur.toLocaleString()} €
        </Typography>
      </Box>
    </Paper>
  );
};
