import { User } from '@flocards/common/src/User';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { userService } from '../services/UserService';
import { storageService } from '../services/StorageService';
import { Colors } from '../theme';

export function NewUser(props: { displayIdentifier: (user: User) => void; navigateBack: () => void }) {
  const [nickname, setNickname] = useState<string>('');

  const rules = {
    counter: (value: string) => value.length < 14,
    symbols: (value: string) => {
      const pattern = /^[a-z \d_\-äÄöÖüÜß]+$/i;
      return pattern.test(value);
    },
  };

  function disableSubmit() {
    return nickname.length < 1 || rules.counter(nickname) !== true || rules.symbols(nickname) !== true;
  }

  async function registerUser(): Promise<void> {
    try {
      const user = await userService.registerUser(nickname);
      storageService.addUserToLocalStorage(user);
      props.displayIdentifier(user);
    } catch (e) {
      alert('Da ist etwas fehlgeschlagen, bitte versuche es erneut');
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography color="primary" variant="subtitle2">
          Gib dir einen Spitznamen:
        </Typography>
        <Box padding={2}>
          <TextField label="Spitzname" onChange={(e) => setNickname(e.currentTarget.value)}></TextField>
        </Box>
        <IconButton
          onClick={registerUser}
          disabled={disableSubmit()}
          style={{ margin: '16px', border: `1px solid ${Colors.Blue}` }}
          size="large">
          <ArrowForwardOutlinedIcon color="primary"></ArrowForwardOutlinedIcon>
        </IconButton>
        <Button onClick={props.navigateBack}>Abbrechen</Button>
      </Box>
    </Box>
  );
}
