import React from 'react';
import { Box } from '@mui/material';
import { Task } from '@flocards/common/src/Task';
import { Solution } from '@flocards/common/src/Solution';

export const AreaLayouter = React.forwardRef<
  HTMLDivElement,
  {
    gridGap: number;
    isTaskArea: boolean;
    tasks: Task[] | Solution[];
    width: number;
    children: React.ReactElement[];
  }
>((props, ref) => {
  let columns = props.tasks.length < 4 ? props.tasks.map(() => `${props.width}px`).join(' ') : '';

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40%',
        padding: props.gridGap,
      }}
      ref={ref}
    >
      {props.children.length < 4 && (
        <Box
          m={0}
          display="grid"
          gridTemplateRows={`${props.width}px`}
          gridTemplateColumns={columns}
          rowGap={`${props.gridGap}px`}
          columnGap={`${props.gridGap}px`}
        >
          {props.children}
        </Box>
      )}
      {props.children.length === 4 && (
        <Box
          m={0}
          display="grid"
          gridTemplateRows={`${props.width}px ${props.width}px`}
          gridTemplateColumns={`${props.width}px ${props.width}px`}
          rowGap={`${props.gridGap}px`}
          columnGap={`${props.gridGap}px`}
        >
          {props.children}
        </Box>
      )}
      {props.children.length > 4 && (
        <Box
          m={0}
          display="grid"
          gridTemplateRows={`${props.width}px ${props.width}px`}
          gridTemplateColumns={`${props.width}px ${props.width}px ${props.width}px`}
          rowGap={`${props.gridGap}px`}
          columnGap={`${props.gridGap}px`}
        >
          {props.children}
        </Box>
      )}
    </div>
  );
});
