import { useRef } from 'react';
import styled from 'styled-components';
import { imageService } from '../services/ImageService';

export const ImageCard = (props: {
  imgUrl: string;
  borderColor: string;
  maxImageWidth: number;
  isSolution: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ImageCardElement
      className={props.isSolution ? 'solution-card' : ''}
      borderColor={props.borderColor}
      imgUrl={props.imgUrl}
      ref={ref}
      maxImageWidth={props.maxImageWidth}
    ></ImageCardElement>
  );
};

export const ImageCardElement = styled.div`
  width: ${(props: { imgUrl: string; borderColor: string; maxImageWidth: number }) => `${props.maxImageWidth}px`};
  height: ${(props: { imgUrl: string; borderColor: string; maxImageWidth: number }) => `${props.maxImageWidth}px`};
  background: ${(props: { imgUrl: string; borderColor: string }) =>
    `url('${imageService.getImageUrl(props.imgUrl)}') no-repeat center`};
  background-size: contain;
  box-sizing: border-box;
  border: ${(props: { imgUrl: string; borderColor: string }) => `2px solid ${props.borderColor}`};
`;
