import { bucketUrl } from './Api';

class ImageService {
  public getImageUrl(relativeBucketImagePath: string): string {
    const path = relativeBucketImagePath.startsWith('/')
      ? relativeBucketImagePath.substring(1)
      : relativeBucketImagePath;

    return `${bucketUrl}/${path}`;
  }
}

export const imageService = new ImageService();
