import { User } from '@flocards/common/src/User';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { storageService } from '../services/StorageService';
import { IdentifierCodeDisplay } from './IdentifierCodeDisplay';
import { LoginOptions } from './LoginOptions';
import { NewUser } from './NewUser';
import { UserSelection } from './UserSelection';
import { generatePath, useHistory } from 'react-router-dom';
import { categorySelectionRoute } from '../gameSelection/CategorySelection';
import { ReactComponent as Logo } from '../assets/flocards_logo.svg';
import { CurrentUser } from '../MenuContext';

export function RegistrationComponent() {
  const {user, setUser} = useContext(CurrentUser);
  const [localUsers, setLocalUsers] = useState<User[] | null>(null);
  const [activeSlide, setActiveSlide] = useState<number>(1);
  const history = useHistory();

  const slides = {
    userSelection: 1,
    loginOptions: 2,
    newUser: 3,
    identifierDisplay: 4,
  };

  useEffect(() => {
    const users = storageService.getLocalUsers();
    setLocalUsers(users);
    users ? setActiveSlide(slides.userSelection) : setActiveSlide(slides.loginOptions);
  }, [slides.userSelection, slides.loginOptions]);

  function switchToCodeDisplay(user: User): void {
    setActiveSlide(slides.identifierDisplay);
    setUser(user);
  }

  function switchToNewuser() {
    setActiveSlide(slides.newUser);
  }
  function switchToLoginOptions() {
    setActiveSlide(slides.loginOptions);
  }

  function enterGame(user: User): void {
    setUser(user);
    storageService.addUserToLocalStorage(user);
    storageService.logInUser(user);
    history.push(generatePath(categorySelectionRoute));
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
      <Box height="25vh" width="90vw" maxWidth="450px" padding={2} marginBottom={6}>
        <Logo></Logo>
      </Box>
      <Box width="100%" padding={2}>
        {activeSlide === slides.loginOptions && (
          <LoginOptions enterGame={enterGame} createNewUser={switchToNewuser}></LoginOptions>
        )}
        {activeSlide === slides.userSelection && localUsers && (
          <UserSelection users={localUsers} enterGame={enterGame} displayOptions={switchToLoginOptions}></UserSelection>
        )}
        {activeSlide === slides.newUser && (
          <NewUser displayIdentifier={switchToCodeDisplay} navigateBack={switchToLoginOptions}></NewUser>
        )}
        {activeSlide === slides.identifierDisplay && user && (
          <IdentifierCodeDisplay
            user={user}
            enterGame={enterGame}
          ></IdentifierCodeDisplay>
        )}
      </Box>
    </Box>
  );
}
