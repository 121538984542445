import { Category } from "@flocards/common/src/Category";
import { Collection } from "@flocards/common/src/Collection";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { categoryService } from "../services/CategoryService";
import { collectionService } from "../services/CollectionService";
import { imageService } from "../services/ImageService";
import { Colors, theme } from "../theme";
import { gameSelectionRoute } from "./GameSelectionComponent";
import { generatePath, useHistory, useParams } from "react-router-dom";

export const collectionSelectionRoute = "/:categoryId/collection-selection";

export function CollectionSelection() {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const { categoryId } = useParams<{ categoryId: string }>();
  const history = useHistory();

  function onCollectionSelect(collectionId: string) {
    history.push(generatePath(gameSelectionRoute, { categoryId, collectionId }));
  }

  useEffect(() => {
    (async () => {
      const collections = await collectionService.getCollections(categoryId);
      setCollections(collections);
      const category = await categoryService.getCategory(categoryId);
      setCategory(category);
    })();
  }, [categoryId]);

  return (
    <Box>
      {category && (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: theme.spacing(4) }}>
              <CategoryBox imageUrl={category.imageUrl}>
                <Typography variant="h6" color="primary" align="center">
                  {category.description}
                </Typography>
              </CategoryBox>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {collections.map((collection) => (
              <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: theme.spacing(4) }} key={collection.id}>
                <Box display="flex" justifyContent="center">
                  <CollectionBox
                    imageUrl={collection.imageUrl}
                    color={Colors.Blue}
                    onClick={() => {
                      onCollectionSelect(collection.id);
                    }}>
                    <Box display="flex" flexDirection="column" width="100%" p={2} textAlign="center">
                      <Typography color="primary" variant="h6">
                        {collection.title}
                      </Typography>
                      <Typography color="primary">{collection.subtitle}</Typography>
                    </Box>
                  </CollectionBox>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

const CategoryBox = styled(Box)`
  background: ${(props: { imageUrl: string }) => `url(${imageService.getImageUrl(props.imageUrl)}) no-repeat center`};
  background-size: contain;
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const CollectionBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  border: 2px solid ${(props: { imageUrl: string; color: string }) => props.color};
  overflow: hidden;
  background: ${(props: { imageUrl: string; color: string }) =>
    `url(${imageService.getImageUrl(props.imageUrl)}) no-repeat center`};
  background-size: contain;
  box-shadow: 0 0 10px #aaa;

  &:hover {
    cursor: pointer;
  }
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;
