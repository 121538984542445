import { Box, Button, Link, Typography } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import { ReactComponent as CoinIcon } from "../assets/Flotaler.svg";
import { CurrentUser } from "../MenuContext";
import { storageService } from "../services/StorageService";
import { buyCoinsRoute } from "./BuyCoins";

export const coinsPurchaseFailureRoute = "/coins-purchase-failure";

export const CoinsPurchaseFailure: FC = () => {
  const { user, setUser } = useContext(CurrentUser);

  useEffect(() => {
    const loggedInUser = storageService.getLoggedInUser();
    setUser(loggedInUser);
  }, [setUser]);
  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" p={5} alignItems="center">
      <Typography variant="h4" gutterBottom color="primary">
        Das hat leider nicht geklappt.
      </Typography>
      <Typography gutterBottom color="primary">
        Die Zahlung wurde abgebrochen oder es ist ein Fehler aufgetreten. Ihrem Konto wurde nichts abezogen. Bitte
        versuchen Sie es erneut.
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Box width="50px" height="50px" mr={1}>
          <CoinIcon />
        </Box>
        <Typography color="red" variant="h5" sx={{ fontWeight: "bold" }}>
          {user?.coins ?? 0}
        </Typography>
      </Box>
      <Link href={buyCoinsRoute} underline="none">
        <Button variant="contained">Erneut evrsuchen</Button>
      </Link>
    </Box>
  );
};
