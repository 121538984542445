import { Box, Button, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { User } from '@flocards/common/src/User';
import { userService } from '../services/UserService';
import { Colors } from '../theme';

export function LoginOptions(props: { enterGame: (user: User) => void; createNewUser: () => void }) {
  const [identifier, setIdentifier] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [showTextfield, setShowTextField] = useState<boolean>(false);

  async function getUser() {
    try {
      const user = await userService.getUser(identifier);
      props.enterGame(user);
    } catch (error) {
      setErrorMessage('Nicht vorhanden');
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Button color="primary" variant="contained" onClick={props.createNewUser} style={{ marginBottom: '16px' }}>
        Neuen Nutzer erstellen
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setShowTextField(!showTextfield)}
        style={{ marginBottom: '16px' }}
      >
        Login Mit Anmeldecode
      </Button>
      {showTextfield && (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <TextField
            label="Anmeldecode"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Tooltip title='Suche im Downloads-Ordner nach "Flocards-Anmeldecode.txt"'>
                    <HelpIcon color="primary"></HelpIcon>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setIdentifier(e.currentTarget.value)}
            error={!!errorMessage}
            helperText={errorMessage}
          ></TextField>
          <IconButton
            onClick={getUser}
            disabled={identifier === ''}
            style={{ margin: '16px', border: `1px solid ${Colors.Blue}` }}
            size="large"
          >
            <ArrowForwardOutlinedIcon color="primary"></ArrowForwardOutlinedIcon>
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
