import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Category } from '@flocards/common/src/Category';
import styled from 'styled-components';
import { categoryService } from '../services/CategoryService';
import { theme } from '../theme';
import { imageService } from '../services/ImageService';
import { collectionSelectionRoute } from './CollectionSelection';
import { generatePath, useHistory } from 'react-router-dom';

export const categorySelectionRoute = '/category-selection';

export function CategorySelection() {
  const [categories, setCategories] = useState<Category[]>([]);
  const history = useHistory();

  function onCategorySelect(categoryId: string) {
    history.push(generatePath(collectionSelectionRoute, { categoryId }));
  }

  useEffect(() => {
    (async () => {
      const categories = await categoryService.getCategories();
      setCategories(categories);
    })();
  }, []);

  return (
    <Box>
      <Grid container>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} lg={3} style={{ padding: theme.spacing(4) }} key={category.id}>
            <Box display="flex" justifyContent="center">
              <CategoryBox
                imageUrl={category.imageUrl}
                onClick={() => {
                  onCategorySelect(category.id);
                }}
              >
                <Box marginTop="20%" width="90%">
                  <Typography
                    style={{ marginLeft: '5%', marginRight: '5%' }}
                    color="primary"
                    align="center"
                    variant="subtitle2"
                  >
                    {category.description}
                  </Typography>
                </Box>
              </CategoryBox>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const CategoryBox = styled(Box)`
  display: flex;
  justify-content: center;
  width: 70%;
  border-radius: 50%;
  overflow: hidden;
  background: ${(props: { imageUrl: string }) => `url(${imageService.getImageUrl(props.imageUrl)}) no-repeat center`};
  background-size: contain;
  box-shadow: 0 0 10px #aaa;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;
