import { GameStateUpdate } from '@flocards/common/src/GameStateUpdate';
import { User } from '@flocards/common/src/User';
import { api } from './Api';

class UserService {
  public async getUser(id: string): Promise<User> {
    return (await api.getUser(id)).data;
  }

  public async registerUser(nickname: string): Promise<User> {
    const result = await api.registerUser(nickname);
    return result.data;
  }

  public async updateGameState(gameStateUpdate: GameStateUpdate, userId: string): Promise<User> {
    const response = await api.updateGameState(gameStateUpdate, userId);
    return response.data;
  }

  public async updateCoins(amount: number, userId: string): Promise<User> {
    const response = await api.updateCoins(amount, userId);
    return response.data;
  }
}

export const userService = new UserService();
