import { userService } from "./UserService";
import { User } from "@flocards/common/src/User";

class StorageService {
  public getLoggedInUser(): User | undefined {
    try {
      const usersEntry = sessionStorage.getItem("loggedInUser");
      if (usersEntry) {
        const user = JSON.parse(usersEntry);
        return user;
      }
    } catch {
      return undefined;
    }
  }

  public async logInUser(user: User): Promise<void> {
    if (!user.coins) {
      const updatedUser = await userService.getUser(user.userId);
      this.updateStorages(updatedUser);
      return;
    }
    sessionStorage.setItem("loggedInUser", JSON.stringify(user));
  }

  public logOutUser(): void {
    sessionStorage.clear();
  }

  public getLocalUsers(): User[] | null {
    //there is malformed data in the local storage of users who used nicknames with spaces
    this.cleanUpLocalStorage();
    const usersEntry = localStorage.getItem("users");
    if (usersEntry) return JSON.parse(usersEntry);
    return null;
  }

  private cleanUpLocalStorage() {
    try {
      const usersEntry = localStorage.getItem("users");
      if (usersEntry) {
        const users = JSON.parse(usersEntry);
        localStorage.clear();
        const filteredUsers = users?.filter((user: any) => user.userId && user.nickname && user.state);
        localStorage.setItem("users", JSON.stringify(filteredUsers));
      }
    } catch {
      localStorage.clear();
    }
  }

  public addUserToLocalStorage(user: User): void {
    if (!(user.userId && user.nickname && user.state)) {
      return;
    }
    let existingUsers = this.getLocalUsers();
    if (existingUsers === null || !StorageService.foundUserInStorage(existingUsers, user)) {
      if (existingUsers === null) existingUsers = [];
      existingUsers.push(user);
      localStorage.setItem("users", JSON.stringify(existingUsers));
    }
  }

  public updateStorages(updatedUser: User): void {
    if (!(updatedUser.userId && updatedUser.nickname && updatedUser.state)) {
      return;
    }
    sessionStorage.setItem("loggedInUser", JSON.stringify(updatedUser));
    const existingUsers = this.getLocalUsers();
    const updatedUsers: User[] = [];
    if (existingUsers) {
      for (const user of existingUsers) {
        if (user.userId !== updatedUser.userId) {
          updatedUsers.push(user);
        }
      }
    }
    updatedUsers.push(updatedUser);
    localStorage.setItem("users", JSON.stringify(updatedUsers));
    //store.commit("setScore", updatedUser.state.score);
  }

  private static foundUserInStorage(users: User[], user: User): boolean {
    for (const i of users) {
      if (i.userId === user.userId) {
        return true;
      }
    }
    return false;
  }

  public savePaypalOrderId(id: string) {
    localStorage.setItem("ongoingPurchase", id);
  }

  public getOpenTransactionid() {
    return localStorage.getItem("ongoingPurchase");
  }
}

export const storageService = new StorageService();
