import { Category } from '@flocards/common/src/Category';
import { api } from './Api';

class CategoryService {
  public async getCategories(): Promise<Category[]> {
    try {
      const result = await api.getCategories();
      const parsedCategories: Category[] = result.data.categories.map((category: any) => {
        return {
          id: category.id,
          description: category.description,
          imageUrl: category.imageUrl,
        };
      });
      return parsedCategories;
    } catch {
      throw new Error('unable to parse received categories');
    }
  }

  public async getCategory(categoryId: string) {
    const categories = await this.getCategories();
    const category = categories.find((category) => `${category.id}` === categoryId);
    return category;
  }
}

export const categoryService = new CategoryService();
