import { Task } from '@flocards/common/src/Task';
import { Solution } from '@flocards/common/src/Solution';
import { Box } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { imageService } from '../services/ImageService';
import { Colors } from '../theme';

export function MatchComponent(props: { solution: Solution; tasks: Task[]; maxImageWidth: number }) {
  return (
    <MatchComponentBox maxImageWidth={props.maxImageWidth} className="solution-card">
      <SolutionAnimationBox imgUrl={props.solution.imageUrl} color={Colors.Red} />
      {props.tasks.map((t, i) => (
        <TaskAnimationBox imgUrl={t.imageUrl} index={i} key={t.id} color={Colors.Blue} />
      ))}
    </MatchComponentBox>
  );
}

const MatchComponentBox = styled(Box)`
  position: relative;
  width: ${(props: { maxImageWidth: number }) => `${props.maxImageWidth}px`};
  height: ${(props: { maxImageWidth: number }) => `${props.maxImageWidth}px`};
`;

const TaskAnimationBox = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props: { imgUrl: string; index: number }) => `url('${imageService.getImageUrl(props.imgUrl)}')`};
  background-size: cover;
  left: ${(props: { imgUrl: string; index: number; color: string }) => `${props.index * 10}px`};
  top: ${(props: { imgUrl: string; index: number; color: string }) => `${props.index * 10}px`};
  border: 2px solid ${(props: { imgUrl: string; color: string }) => props.color};
  animation: 1s ${keyframes`
    from {
      width: 100%;
      height: 100%;
    }
    to {
      width: 60%;
      height: 60%;
    }
  `};
  animation-fill-mode: forwards;
`;

const SolutionAnimationBox = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${(props: { imgUrl: string; color: string }) => `url('${imageService.getImageUrl(props.imgUrl)}')`};
  background-size: cover;
  border: 2px solid ${(props: { imgUrl: string; color: string }) => props.color};

  animation: 1s ${keyframes`
    from {
      width: 100%;
      height: 100%;
    }
    to {
      width: 60%;
      height: 60%;
    }
  `};
  animation-fill-mode: forwards;
`;
