import { Task } from '@flocards/common/src/Task';
import { Solution } from '@flocards/common/src/Solution';
import { Box } from '@mui/material';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../theme';
import { imageService } from '../services/ImageService';

export function FailedAttemptComponent(props: { resetCurrentAttepmt: () => void; task?: Task; solution?: Solution }) {
  setTimeout(props.resetCurrentAttepmt, 500);
  return (
    <>
      {props.task && props.solution && (
        <FailedAttemptAnimation imgUrl={props.task.imageUrl} color={Colors.Blue}></FailedAttemptAnimation>
      )}
    </>
  );
}

const FailedAttemptAnimation = styled(Box)`
  background: ${(props: { imgUrl: string; color: string }) =>
    `url('${imageService.getImageUrl(props.imgUrl)}') no-repeat center`};
  background-size: contain;
  border: 2px solid ${(props: { imgUrl: string; color: string }) => props.color};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: 1s ${keyframes`
      {
        0% {transform: translateX(0)}
        6.5% {transform: translateX(-12px) rotateY(-9deg);}
        18.5% {transform: translateX(10px) rotateY(7deg);}
        31.5% {transform: translateX(-6px) rotateY(-5deg);}
        43.5% {transform: translateX(4px) rotateY(3deg);}
        50% {transform: translateX(0)}
      }`};
  animation-fill-mode: forwards;
`;
