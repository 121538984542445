import { Task } from '@flocards/common/src/Task';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Colors } from '../theme';
import { ImageCard } from './ImageCard';

interface TaskComponentProps {
  task: Task;
  index: number;
  solvedTasks: string[];
  maxImageWidth: number;
  isDragging: boolean;
  setSolvedTaskIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSolvedSolutionIds: React.Dispatch<React.SetStateAction<string[]>>;
  setDraggable: (id: string | undefined) => void;
  setCurrentAttempt: (attempt: { taskId: string; taskSolutionId: string; attemptedSolutionId: string }) => void;
  setTasksWithFailedAttempts: React.Dispatch<React.SetStateAction<string[]>>;
}

function isReactMouseEvent(e: any): e is React.MouseEvent {
  return e.clientX && e.clientY;
}
function isReactTouchEvent(e: any): e is React.TouchEvent {
  return e.changedTouches;
}

function findDropTargetParent(element: Element | undefined | null) {
  let dropTarget;
  if (element) {
    dropTarget = element;
    if (dropTarget.parentElement?.classList.contains('drop-target')) {
      return dropTarget.parentElement;
    } else {
      findDropTargetParent(dropTarget.parentElement);
    }
  }
}

function checkSolution(posX: number, posY: number, props: TaskComponentProps) {
  const elementsAtPoint = document.elementsFromPoint(posX, posY);
  const solutionElement = elementsAtPoint.find((e) => e.classList.contains('solution-card'));
  const dropTarget = findDropTargetParent(solutionElement);

  if (dropTarget) {
    const solutionId = dropTarget.attributes.getNamedItem('data-solution-id')?.value;
    if (solutionId) {
      if (props.task.solutionId === solutionId) {
        props.setSolvedTaskIds((solvedTasks) => [...solvedTasks, props.task.id]);
        props.setSolvedSolutionIds((solvedSolutions) => [...solvedSolutions, props.task.solutionId!]);
      } else {
        props.setCurrentAttempt({
          taskId: props.task.id,
          taskSolutionId: props.task.solutionId ?? '',
          attemptedSolutionId: solutionId,
        });
        props.setTasksWithFailedAttempts((tasksWithFailedAttempts) =>
          !tasksWithFailedAttempts.find((taskId) => taskId === props.task.id)
            ? [...tasksWithFailedAttempts, props.task.id]
            : [...tasksWithFailedAttempts]
        );
      }
    }
  }
}

export function TaskComponent(props: TaskComponentProps) {
  const [zIndex, setZIndex] = useState<number | undefined>(undefined);
  const [delta, setDelta] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [startingPosition, setStartingPosition] = useState<{ x: number; y: number } | undefined>(undefined);

  const handleStart = (e: DraggableEvent, data: DraggableData) => {
    if (!props.isDragging) {
      props.setDraggable(props.task.id);
    }
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setZIndex(9000);
    setDelta((currentDelta) => ({
      x: currentDelta.x + data.deltaX,
      y: currentDelta.y + data.deltaY,
    }));
  };

  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    setStartingPosition({ x: data.x - delta.x, y: data.y - delta.y });
    setDelta({ x: 0, y: 0 });
    setZIndex(undefined);
    if (isReactMouseEvent(e)) {
      checkSolution(e.clientX, e.clientY, props);
    }
    if (isReactTouchEvent(e)) {
      if (e.changedTouches.length === 1) {
        const theTouch = e.changedTouches[0];
        checkSolution(theTouch.clientX, theTouch.clientY, props);
      }
    }
    props.setDraggable(undefined);
  };

  return (
    <Box position="relative" zIndex={zIndex ?? 'auto'}>
      {!props.solvedTasks.includes(props.task.id) && (
        <Draggable
          disabled={props.isDragging}
          onStart={handleStart}
          onStop={handleStop}
          onDrag={handleDrag}
          position={startingPosition}
        >
          <div>
            <ImageCard
              imgUrl={props.task.imageUrl}
              borderColor={Colors.Blue}
              maxImageWidth={props.maxImageWidth}
              isSolution={false}
            />
          </div>
        </Draggable>
      )}
    </Box>
  );
}
