import { Task } from '@flocards/common/src/Task';
import { Solution } from '@flocards/common/src/Solution';
import { ImageCard } from './ImageCard';
import { MatchComponent } from './MatchComponent';

export function SolutionComponent(props: {
  solution: Solution;
  solvedSolutions: string[];
  solvedTaskIds: string[];
  tasks: Task[];
  maxImageWidth: number;
}) {
  //get the tasks that are solved and belong to the solution that is displayed
  const solvedTasks: Task[] = props.tasks.filter(
    (t) => props.solvedTaskIds.find((id) => id === t.id) && t.solutionId === props.solution.id
  );

  return (
    <>
      {!props.solvedSolutions.includes(props.solution.id) ? (
        <div className="drop-target" data-solution-id={props.solution.id}>
          <ImageCard
            isSolution={true}
            imgUrl={props.solution.imageUrl}
            borderColor="#D57D7D"
            maxImageWidth={props.maxImageWidth}
          />
        </div>
      ) : (
        <div className="drop-target" data-solution-id={props.solution.id}>
          <MatchComponent
            solution={props.solution}
            tasks={solvedTasks.filter((t) => t.solutionId === props.solution.id)}
            maxImageWidth={props.maxImageWidth}
          />
        </div>
      )}
    </>
  );
}
