import { User } from '@flocards/common/src/User';
import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { Colors } from '../theme';

export function UserSelection(props: { users: User[]; enterGame: (user: User) => void; displayOptions: () => void }) {
  const colors = [Colors.BubbleYellow, Colors.BubbleOrange, Colors.BubblePink, Colors.BubbleGreen, Colors.BubbleBlue];

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
        {props.users.map((user, index) => (
          <UserBubble
            key={user.userId}
            backgroundColor={colors[index % colors.length]}
            onClick={() => props.enterGame(user)}
          >
            <Typography color="primary" variant="h6">
              {user.nickname}
            </Typography>
          </UserBubble>
        ))}
      </Box>
      <Box>
        <Button onClick={props.displayOptions} variant="outlined" color="primary">
          Anderen Spieler starten
        </Button>
      </Box>
    </Box>
  );
}

export const UserBubble = styled.button`
  width: 146px;
  height: 146px;
  margin: 40px 36px 36px;
  padding: 7px;
  background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};
  border-radius: 500px;
  border: 0px;
`;
